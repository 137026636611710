.navbar-logo {
  width: 4.5rem;
  height: auto;
  transition: all 0.3s ease;
  filter: invert(0);
  margin: 0 0 0 1rem !important;

  &:hover {
    filter: grayscale(0.5);
    transform: scale(1.1);
  }

  @media screen and (min-width: 125rem) {
    width: 9rem;
  }

  [data-theme="dark"] & {
    filter: invert(1);
  }
}

.bs-navbar-brand,
.navbar-brand {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.navbar {
  background: linear-gradient(
    rgba(255, 255, 255, 0.6) 0%,
    rgba(200, 200, 200, 0.6) 100%
  );
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);

  [data-theme="dark"] & {
    background: linear-gradient(
      rgba(20, 20, 20, 0.6) 0%,
      rgba(50, 50, 50, 0.6) 100%
    );
  }
}

.theme-switch {
  z-index: 10;
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
  cursor: pointer;

  input {
    display: none;
  }

  .theme-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-color);
    font-size: 1rem;
    z-index: 1;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-color);
    transition: all 0.3s ease;
    border-radius: 24px;

    &:before {
      position: absolute;
      content: "";
      height: 1.2rem;
      width: 1.2rem;
      left: 0.15rem;
      bottom: 0.15rem;
      background-color: var(--primary-color);
      transition: all 0.3s ease;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: var(--secondary-color);
  }

  input:checked + .slider:before {
    transform: translateX(1rem);
  }

  input + .theme-icon {
    transition: transform 0.3s ease;
  }

  input:checked + .theme-icon {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  &:hover .slider {
    background-color: var(--secondarybg-color);
    transform: scale(1.05);
  }
}

.navbar-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-links-container {
    flex: 1;
    display: flex;
    justify-content: center;

    .nav {
      display: flex;
      gap: 1.5rem;
    }
  }

  .theme-switch-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.nav-link {
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  color: var(--primary-color);

  &:hover {
    color: var(--highlight-color);
  }

  &:hover,
  &.focus {
    font-weight: 700;
    color: var(--highlight-color);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }

  &.active {
    font-weight: 700;
    color: var(--highlight-color);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }

  [data-theme="dark"] & {
    color: var(--dark-primary-color);

    &:hover,
    &:focus,
    &.active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    padding: 0.5rem 1rem;

    .navbar-collapse {
      flex-direction: column;
      align-items: center;
      width: 100%;

      .nav {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }

      .nav-link {
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
      }

      .theme-switch-wrapper {
        position: static;
        order: 3;
        margin-top: 1rem;
      }
    }
  }
}
