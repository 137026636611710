.site-footer {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-top: 1px solid var(--border-light);
  padding: 1.5rem 0;
  text-align: center;

  .footer-container {
    .row {
      align-items: center;
      justify-content: center;
    }

    .footer-about,
    .footer-social,
    .footer-top {
      font-weight: 500;
      padding: 0.5rem;
      transition: color var(--transition-speed);

      &:hover {
        color: var(--accent-color);
      }
    }

    .footer-social {
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        justify-content: center;

        > div {
          margin: 0 0.5rem;

          a {
            font-size: 1.3em;
            padding: 0.5em 0.5em;
            color: var(--gray-color);
            transition: color var(--transition-speed);
            border: none !important;
            outline: none !important;
            text-decoration: none !important;

            &:link,
            &:visited,
            &:hover,
            &:active,
            &:focus {
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
            }

            &:hover {
              color: var(--text-highlight);
            }
          }
        }
      }
    }
  }

  .footer-top {
    button,
    a {
      display: inline-block;
      color: var(--text-primary);
      background-color: var(--bg-secondary);
      text-decoration: none;
      border: none !important;
      outline: none !important;

      &:hover,
      &:focus {
        border-bottom: none !important;
      }
    }
  }
}

@media (max-width: 576px) {
  .footer-about,
  .footer-social,
  .footer-top {
    font-size: 1em;
    &.text-center {
      text-align: center;
    }
  }

  .footer-social {
    > div {
      flex-direction: row;

      > div {
        a {
          font-size: 1.5em;
          padding: 0.5em 0.5em;
        }
      }
    }
  }
  .footer-top a {
    font-size: 1.5em;
  }
}
