.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Assuming your Routes container will have this class or add an additional div with this class
.main-content {
  flex: 1; // This will make the main content area grow and take the available space, pushing the footer to the bottom
}

// Ensure that the Navbar and Footer have their sizes determined by their content
.navbar,
.footer {
  flex-shrink: 0;
}
