@mixin hover-transition-effect {
  transition: transform var(--transition-speed);
  &:hover {
    transform: translateY(-5px);
  }
}

@mixin form-input {
  width: 100%;
  padding: 1.2rem 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-light);
  border-radius: var(--border-radius-common);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  transition: border-color var(--transition-speed),
    background-color var(transition-speed);
  &:focus {
    border-color: var(--link-hover);
    background-color: var(--bg-primary);
    box-shadow: var(--border-light);
  }
}

.contact-container {
  width: 100%;
  padding: 0rem 0 4rem;
  
  h2,
  a {
    font-weight: 600;
    color: var(--link);
    transition: color var(--transition-speed);
    text-decoration: none;

    &:hover {
      color: var(--link-hover);
    }
  }

  h1 {
    margin: 6rem 0 4rem;
    font-size: 2.8em;
    line-height: 1.4;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px;
    color: var(--text-primary) !important;
  }
}

.info-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 1rem;
  padding: 1rem 2rem;
  background: linear-gradient(
    135deg,
    var(--secondarybg-color) 0%,
    var(--highlight-color) 100%
  );
  border-radius: 12px;
  box-shadow: 0px 0px 15px var(--text-primary);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 20px var(--accent-primary);
  }

  .info-icon img {
    margin-bottom: 1.5rem;
    width: 100px;
    transition: transform var(--transition-speed);

    &:hover {
      transform: scale(1.1);
    }
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 1rem;
  }

  a {
    font-size: 1.4em;
  }
}

.contact-form-section {
  margin-top: 3rem;
  padding: 2rem;
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.contact-form {
  input,
  textarea {
    @include form-input;
    border: 1px solid var(--border-light);
  }

  button {
    padding: 1.2rem 2rem;
    border: none;
    border-radius: var(--border-radius-common);
    background-color: var(--link);
    color: var(--bg-primary);
    cursor: pointer;
    transition: background-color var(--transition-speed);

    &:hover {
      background-color: var(--link-hover);
    }

    &[disabled] {
      background-color: var(--border-light);
    }
  }

  .error-message {
    color: var(--color-error);
    font-size: 0.9em;
  }

  input.error,
  textarea.error {
    border-color: var(--color-error);
    background-color: none;
  }
}

.captcha-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  padding: 1.5rem;

  .captcha-display {
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 150px;
    padding: 0.5rem 1rem;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    border-radius: var(--border-radius-common);
    font-size: 1.8rem;
    font-weight: bold;
    user-select: none;
    text-align: center;
    box-shadow: inset 0 0 8px var(--accent-primary);
    letter-spacing: 3px;
  }

  .captcha-controls {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
  }

  .refresh-captcha,
  .read-captcha {
    padding: 0.5rem;
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: none;
    color: var(--color-black);
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &:hover {
      color: var(--color-black);
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    }
  }

  input[type="text"] {
    flex-grow: 1;
    max-width: 300px;
    height: 50px;
    margin: 0;
    padding: 0.5rem 1rem;
    border: 2px solid var(--border-light);
    border-radius: var(--border-radius-common);
    background-color: var(--color-white);
    color: var(--color-black);
    font-size: 1rem;

    &:focus {
      border-color: var(--color-black);
      outline: none;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
}

button[type="submit"] {
  width: 100%;
  margin-top: 1rem;
  padding: 1.2rem 0;
  background-color: var(--link);
  color: var(--bg-primary);
  border-radius: var(--border-radius-common);
  border: none;
  transition: background-color var(--transition-speed),
    box-shadow var(--transition-speed);

  &:hover {
    background-color: var(--btn-hover-bg);
    box-shadow: var(--border-light);
  }

  &:active {
    transform: translateY(-2px);
    box-shadow: var(--border-light);
  }
}

// Media Queries

// Media Queries for 4k devices
@media (min-width: 2560px) {
  $font-size-4k: 2rem;

  body {
    font-size: $font-size-4k;
  }

  .contact-container {
    max-width: 80%;
    padding: 2rem 0;
    padding-bottom: 6rem;

    .info-col {
      padding: 2rem 4rem;

      .info-icon img {
        width: 150px;
      }

      h2 {
        font-size: $font-size-4k;
      }
    }

    .contact-form {
      input,
      textarea,
      button,
      label {
        font-size: $font-size-4k;
        padding: 1.5rem 2rem;
      }
    }

    .captcha-section {
      .captcha-display {
        font-size: 2.5rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .contact-container {
    padding: 0rem 0;
    padding-bottom: 6rem;
    width: 100%;

    .head-text {
      padding: 0 1rem;
    }

    .info-icon img {
      width: 100% !important;
      height: auto;
    }

    .info-col h2 {
      font-size: 75%;
    }

    .captcha-section {
      flex-direction: column;

      .captcha-display {
        order: 1;
        margin-bottom: 0.5rem;
      }

      .captcha-controls,
      .refresh-captcha,
      .read-captcha {
        order: 2;
        width: 50%;
        margin-bottom: 0.5rem;
      }

      input[type="text"] {
        order: 3;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}
