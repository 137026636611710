@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin card-style {
  background-color: var(--bg-secondary);
  border-radius: 24px;
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-duration),
    box-shadow var(--transition-duration);
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

// Resets
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Base styles
.app__about {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  overflow-x: hidden;
  padding-top: 6rem;
}

// Hero section styles
.hero-section {
  @include flexCenter;
  flex-direction: column;
  padding-top: 7rem;
  text-align: center;
  background-color: var(--bg-primary);

  .head-text {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--text-primary);
  }

  .intro {
    font-size: 1.5rem;
    color: var(--text-secondary);
  }
}

// Expertise section styles
.expertise-section {
  .expertise-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: stretch;
  }

  .card {
    @include card-style;
    flex: 1 0 21%;
    max-width: 21%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5rem;
    min-height: 300px;
    padding: 1rem;

    .role-image-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;

      .role-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }

    .role-title,
    p {
      color: var(--text-primary);
      text-align: center;
      margin-bottom: 1rem;
    }

    .text-container {
      color: var(--text-primary);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      height: 100%;
    }

    .role-title {
      font-size: 1.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      min-height: 3em;
    }

    p {
      font-size: 1rem;
      min-height: 4em;
      margin-bottom: 0;
    }
  }
}

// Additional information styles
.additional-info {
  background: var(--bg-secondary);
  padding: 4rem 2rem;
  box-shadow: var(--box-shadow);
}

// CTA section styles
.cta-section {
  padding: 3rem 0 !important;
  text-align: center;

  h3 {
    font-size: 2.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
  }

  a {
    color: var(--text-secondary);
    text-decoration: none;
  }

  .cta-link {
    text-decoration: none;
    color: var(--accent-color);
    &:hover {
      color: var(--box-shadow);
    }
  }

  .cta-btn {
    padding: 0.75rem 1.5rem !important;
    background-color: var(--accent-color);
    color: var(--bg-secondary);
    border: none;
    border-radius: var(--border-radius);
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color var(--transition-duration);
    &:hover {
      color: var(--box-shadow);
    }
  }
}

@media (min-width: 584px) and (max-width: 768px) {
  .hero-section {
    padding-top: 6rem;
    padding-bottom: 0rem !important;
    margin-bottom: 0rem !important;

    .head-text {
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }

    .intro {
      font-size: 1rem;
    }
  }

  .expertise-section .card {
    flex-basis: calc(100% - 80%) !important;
    max-width: calc(100% - 80%) !important;
    margin: 0.5rem;
    padding: 0.5rem;

    .role-image-wrapper {
      padding-top: 100%;
      width: auto;

      .role-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .text-container {
      display: flex !important;
      flex-direction: column !important;
      height: 100%;
    }

    .role-title {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    p {
      justify-content: flex-start !important;
      font-size: 0.75rem;
      min-height: 4em;
      margin-bottom: 1em;
    }
  }

  .additional-info {
    padding: 2rem 1rem;

    h3 {
      font-size: 1.5rem !important;
    }

    p {
      font-size: 1rem !important;
    }
  }

  .cta-section {
    padding: 2rem 0;

    h3 {
      font-size: 1.5rem !important;
    }

    p {
      font-size: 1rem !important;
    }

    .cta-btn {
      padding: 0.7rem 1.4rem;
      font-size: 0.9rem !important;
    }
  }
}

@media (max-width: 584px) {
  .app__about {
    padding-top: 4rem;

    .hero-section {
      padding: 1.5rem 1rem;
      margin-bottom: 0rem !important;

      .head-text {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }

      .intro {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    .expertise-section .expertise-grid {
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 0;
    }

    .expertise-section .card {
      flex: 0 0 45%;
      max-width: 45%;
      margin: 1rem 1.5%;
      padding: 1rem;
      box-sizing: border-box;

      .role-image-wrapper {
        padding-top: 100;
        width: 100%;
        position: relative;

        .role-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-container {
        text-align: center;
        padding-top: 0.5rem;
      }

      .role-title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.75rem;
      }
    }

    .additional-info {
      padding: 2rem 1rem;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      p {
        font-size: 1rem;
      }
    }

    .cta-section {
      padding: 2rem 1rem;

      h3 {
        font-size: 1.5rem;
        font-weight: 700;
      }

      p {
        font-size: 1rem;
      }

      .cta-btn {
        padding: 0.5rem 1.5rem !important;
        font-size: 1rem !important;
        margin-top: 1rem !important;
      }
    }
  }
}

@media (max-width: 424px) {
  .app__about {
    padding-top: 4rem;

    .hero-section {
      padding: 1.5rem 1rem;
      margin-bottom: 0rem !important;

      .head-text {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }

      .intro {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    .expertise-section .expertise-grid {
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 0;
    }

    .expertise-section .card {
      flex: 0 0 44%;
      max-width: 45%;
      margin: 0.5rem 1.5%;
      padding: 0.5rem;
      box-sizing: border-box;

      .role-image-wrapper {
        padding-top: 100;
        width: 100%;
        position: relative;

        .role-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-container {
        text-align: center;
        padding-top: 0.5rem;
      }

      .role-title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.75rem;
      }
    }

    .additional-info {
      padding: 2rem 1rem;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      p {
        font-size: 1rem;
      }
    }

    .cta-section {
      padding: 2rem 1rem;

      h3 {
        font-size: 1.5rem;
        font-weight: 700;
      }

      p {
        font-size: 1rem;
      }

      .cta-btn {
        padding: 0.5rem 1.5rem !important;
        font-size: 1rem !important;
        margin-top: 1rem !important;
      }
    }
  }
}
