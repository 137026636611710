$navbar-height-desktop: 1rem;
$navbar-height-tablet: 4rem;
$navbar-height-mobile: 6rem;

// General styles and mixins
@mixin hover-transition-effect {
  transition: transform var(--transition-speed);
  &:hover {
    transform: translateY(-5px);
  }
}

@mixin form-input {
  width: 100%;
  padding: 1.2rem 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-light);
  border-radius: var(--border-radius-common);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  transition: border-color var(--transition-speed),
    background-color var(--transition-speed);
  &:focus {
    border-color: var(--link-hover);
    background-color: var(--bg-primary);
    box-shadow: var(--accent-primary);
  }
}

@mixin center-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skills-container {
  margin: 6vw 0;
  padding-top: 2rem;
  padding-top: $navbar-height-desktop;

  @media (max-width: 991px) {
    padding-top: 4rem;
  }
}

.skills-section,
.expertise-section,
.cta-section {
  margin-bottom: 3rem !important;
}

.hero-section .head-text {
  font-weight: 700;
  font-size: calc(1.5rem + 1.5vw);
  color: var(--text-primary);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hero-section .intro {
  font-weight: 400;
  font-size: calc(1rem + 0.5vw);
  color: var(--text-secondary);
  margin-bottom: 0rem;
}

.hero-section {
  padding-top: 0rem !important;
}

.text-sphere {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s, scale 0.3s;
  &:hover {
    transform: scale(1.02);
  }
  .tagcloud {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: calc(0.8em + 0.5vw);
    color: var(--secondary-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    perspective: 800px;
    span {
      text-transform: uppercase;
    }
  }
}

// Responsive grid for skill cards
.skills-row {
  display: flex;
  // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  justify-content: center;
}

// Skill card styles
.skill-card {
  @include hover-transition-effect;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-light);
  border-radius: var(--border-radius-common);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  padding: 1rem 0;
  height: 100%;
  width: auto;
  min-width: 8rem !important;
  max-width: 8rem;
  min-height: 8rem;
  max-height: 8rem;

  .skill-icon {
    width: auto;
    height: 3rem;
    margin-bottom: 0.5rem;
  }

  .skill-name {
    font-size: 1rem;
    color: var(--text-primary);
  }
}

.cta-section .cta-link .cta-btn {
  padding: 1rem 2rem;
  border-radius: 5px;
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  transition: background-color var(--transition-speed),
    transform var(--transition-speed);
  &:hover {
    background-color: var(--btn-hover-bg);
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: translateY(-1px);
  }

  h3 {
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: var(--text-primary) !important;
  }

  a {
    color: var(--link);
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--accent-primary);
      text-decoration: none;
    }
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    color: var(--accent-primary);
  }
}

.cta-section {
  padding-top: 0rem;
  margin-bottom: 0rem !important;
}

// Media queries
@media (min-width: 600px) {
  .skills-row {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (min-width: 900px) {
  .skills-row {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (min-width: 1200px) {
  .skills-row {
    grid-template-columns: repeat(5, 1fr);
  }
}
