@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --transition-speed: 0.3s;
  --border-radius-common: 7px;

  // Define a palette of grayscale colors
  --color-deepestGray: #0d0d0d;
  --color-superDarkGray: #1e1e1e;
  --color-darkGray: #2b2b2b;
  --color-midGray: #5a5a5a;
  --color-lightGray: #9e9e9e;
  --color-ultraLightGray: #d0d0d0;
  --color-barelyGray: #f7f7f7;
  --color-almostWhite: #fefefe;
  --color-almostBlack: #090909;

  // Define gradients
  --gradient-light: radial-gradient(
    circle,
    var(--color-almostWhite) 0%,
    var(--color-barelyGray) 100%
  );
  --gradient-dark: radial-gradient(
    circle,
    var(--color-almostBlack) 0%,
    var(--color-superDarkGray) 100%
  );

  // Define default theme colors
  --bg-primary: var(--color-almostWhite);
  --bg-secondary: var(--color-barelyGray);
  --text-primary: var(--color-deepestGray);
  --link: var(--color-darkGray);
  --link-hover: var(--color-midGray);
  --border-light: var(--color-ultraLightGray);
  --btn-hover-bg: var(--color-midGray);
  --accent-primary: var(--color-midGray);
  --accent-secondary: var(--color-darkGray);
  --main-title: var(--color-lightGray);
}

[data-theme="dark"] {
  --bg-primary: var(--color-superDarkGray);
  --bg-secondary: var(--color-deepestGray);
  --text-primary: var(--color-almostWhite);
  --link: var(--color-ultraLightGray);
  --link-hover: var(--color-lightGray);
  --border-light: var(--color-midGray);
  --btn-hover-bg: var(--color-lightGray);
  --accent-primary: var(--color-lightGray);
  --accent-secondary: var(--color-ultraLightGray);
  --main-title: var(--color-lightGray);
}

body {
  font-family: var(--font-base);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  transition: var(--transition-speed);
}

// Simplified global styles
a,
.btn,
.nav-link,
input,
textarea,
select {
  transition: all var(--transition-speed);
  color: var(--link);

  &:hover,
  &:focus {
    opacity: 0.8; // Apply hover effect to all links and form controls
    color: var(--link-hover);
  }

  // Specific focus styles for form controls
  &:focus {
    border-color: var(--link-hover);
    box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.25);
  }
}

// Specific styles for elements under the dark theme
[data-theme="dark"] {
  .shadow {
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--text-primary);
  }

  .nav-link.active,
  .nav-link:hover,
  .nav-link:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

// Shared styles for tables, buttons, and modals
table,
th,
td,
.btn-primary,
.btn-secondary,
.modal-content {
  border-color: var(--border-light);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-primary:active,
.btn-secondary:active {
  background-color: var(--btn-hover-bg);
}

// Utility classes for margins and alert messages
.mt-minimal,
.mb-minimal {
  margin: 0.5rem 0;
}
.alert-success {
  background-color: var(--btn-hover-bg);
  color: var(--link);
}
.alert-danger {
  background-color: var(--bg-primary);
  color: var(--bg-secondary);
}

// Progress bar styles
.progress-bar {
  background-color: var(--text-primary);
}

// Text styles
.text-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary) !important;
  margin-bottom: 1rem;
}

.text-subtitle {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-primary) !important;
  margin-bottom: 0.5rem;
}

.text-body {
  font-size: 1rem;
  color: var(--text-primary) !important;
  line-height: 1.6;
}

.text-small {
  font-size: 0.875rem;
  color: var(--text-primary) !important;
}

.text-link {
  color: var(--link) !important;
  &:hover,
  &:focus {
    color: var(--link-hover) !important;
    text-decoration: none;
  }
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}
