$max-iterations: 21;

// Using variables from index.scss
$text-animation-duration: var(--transition-speed);
$hover-color: var(--main-title) !important;

.text-animate,
.text-animate-hover {
  display: inline-block;
  min-width: 10px;
  animation-fill-mode: both;
}

.text-animate {
  opacity: 0;
  animation-name: bounceIn;
  animation-duration: $text-animation-duration;
  animation-timing-function: forwards;

  @for $i from 1 through $max-iterations {
    &._#{$i} {
      animation-delay: #{($i * 0.1)}s;
    }
  }
}

.text-animate-hover {
  transition: color $text-animation-duration;

  &:hover {
    animation-name: rubberBand;
    animation-duration: $text-animation-duration;
    animation-fill-mode: forwards;
    color: $hover-color;
  }
}
