// Home.scss
.home-container {
  padding-top: calc(100vh / 6);
  max-width: 100% !important;

  .main-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: auto;
  }

  .profile-col {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .profile-image {
      width: 100%;
      max-width: 230px;
      margin: 0 auto;
      border-radius: 16px;
      border: 3px solid transparent;
      box-shadow: inset 0 0 15px var(--accent-secondary) !important;
    }

    .name {
      color: (--text-primary) !important;
      font-size: calc(2rem + 2vw);
    }

    .description {
      font-size: calc(0.5rem + 1vw);
      padding: 5% 10%;
      margin-top: 1rem;
    }

    .download-btn {
      font-size: calc(1rem + 0.3vw);
      padding: calc(0.5rem + 0.2vw) calc(1rem + 0.5vw);
      margin-top: 10%;
      background: var(--bg-primary) !important;
      color: var(--text-primary) !important;
      border: 2px solid var(--text-primary) !important;
      border-radius: 5px;
      transition: var(--transition-speed);
      display: inline-block;
      text-decoration: none;

      &:hover {
        background: var(--text-primary) !important;
        color: var(--bg-primary) !important;
        border-color: var(--accent-secondary);
      }
    }

    .description-row {
      justify-content: center;
      align-items: center;
    }
  }

  .cube-col {
    padding: 2rem;
  }
}

@media (min-width: 2560px) {
  .home-container {
    max-width: 100% !important;
    padding-top: 10vw !important;
    padding-bottom: 0 !important;
    height: none !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-evenly !important;
    max-width: none !important;
  }

  .main-row {
    width: 100% !important;
    max-width: 2560px !important;
    margin: 0 auto !important;
    flex-wrap: nowrap !important;
  }

  .profile-col,
  .cube-col {
    width: 50% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
  }

  .profile-image {
    width: 100%;
    max-width: 500px !important;
    object-fit: contain;
  }

  .name {
    font-size: 5vw !important;
    margin: 1vw 1vw !important;
  }

  .description {
    font-size: 1.5vw !important;
    padding: 4vw 2vw !important;
    text-align: center !important;
  }

  .download-btn {
    font-size: 1.5vw !important;
    padding: 1vw 2vw !important;
    margin: 2vw 0 !important;
  }

  .cube {
    width: 25vw !important;
    height: 25vw !important;
    max-width: 600px !important;
    max-height: 600px !important;
    margin: 2vw 0 !important;
  }
}

// This media query adjusts styles for medium devices like tablets
@media (max-width: 1200px) {
  .home-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    max-width: 100% !important;
  }

  .main-row {
    flex-direction: column;
    align-items: center;
  }

  .profile-col,
  .cube-col {
    width: 100%;
    max-width: none;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .profile-image {
    width: 50%;
    max-width: 300px;
    margin-bottom: 1rem;
  }

  .name,
  .description {
    text-align: center;
  }

  .cube {
    max-width: 300px;
    margin: 0 auto;
  }
}

// This media query adjusts styles for devices like iPad Mini and iPad Air
@media (min-width: 768px) and (max-width: 1200px) {
  // Assuming iPad Mini and Air fall under this width
  .home-container {
    padding-top: 6rem !important;
    padding-bottom: 3rem !important;
  }

  .main-row {
    flex-direction: column !important;
    align-items: center !important;
  }

  // This selector will target the specific row for the profile image and title
  div.profile-col > div.align-items-center {
    width: 90% !important;
    justify-content: center !important;
  }

  div.cube-col {
    width: 100% !important;
    justify-content: center !important;
    padding: 15% 0 !important;
  }

  .profile-col {
    width: 100% !important;
    max-width: none !important;

    .profile-image {
      width: 100% !important;
      max-width: 350px !important;
    }

    .name {
      color: var(--text-primary);
      font-size: 5rem !important;
      padding: 0 5% !important;
      margin-bottom: 1rem !important;
    }

    .description {
      font-size: 2rem !important;
      padding: 2rem 5% !important;
      margin-bottom: 1rem !important;
    }

    .download-btn {
      font-size: 2rem !important;
      padding: 1rem 2rem !important; // Adjust the padding for the button
    }
  }
}

// Small devices (landscape phones, 576px to 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .home-container {
    padding-top: 4rem !important;
    padding-bottom: 2rem !important;
    display: block !important;
  }

  .main-row {
    max-width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .profile-col {
    order: 1 !important;
    width: 100% !important;
    text-align: center !important;
    .profile-image {
      width: 35vw !important;
      max-width: 200px !important;
      margin: auto !important;
      margin-bottom: 1rem !important;
    }

    .name {
      font-size: 2.5rem !important;
      margin-bottom: 0.5rem !important;
    }

    .description {
      font-size: 1.25rem !important;
      padding: 0 1rem !important;
    }

    .download-btn {
      font-size: 1rem !important;
      padding: 0.7rem 1.4rem !important;
      margin-top: 1rem !important;
    }
  }

  .cube-col {
    order: 2 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .cube {
      width: 35vw !important;
      max-width: 200px !important;
    }
  }
}

// Extra small devices (phones, less than 576px)
@media (max-width: 575px) {
  .home-container {
    padding-top: 6rem;
    padding-bottom: 0 !important;
  }

  .profile-col {
    .profile-image {
      max-width: 200px !important;
    }

    .text-center.ps-5 {
      padding-left: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .name {
      font-size: 3rem !important;
    }

    .download-btn {
      font-size: 0.95rem !important;
      margin: 1rem !important;
      padding: 0.75rem 1.5rem !important;
    }

    .description {
      padding-top: 2rem !important;
      font-size: 1.25rem !important;
    }
  }

  .cube-col {
    order: 2 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 8rem !important;
    .cube {
      width: 35vw !important;
      max-width: 200px !important;
    }
  }
}
