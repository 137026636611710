$cube-half-size: 12.5vw;
$transforms: translateZ($cube-half-size),
  rotateY(90deg) translateZ($cube-half-size),
  rotateY(90deg) rotateX(90deg) translateZ($cube-half-size),
  rotateY(180deg) rotateZ(90deg) translateZ($cube-half-size),
  rotateY(-90deg) rotateZ(90deg) translateZ($cube-half-size),
  rotateX(-90deg) translateZ($cube-half-size);
$colors: #dd0031, #f06529, #28a4d9, #5ed4f4, #efd81d, #ec4d28;

.cube-wrapper {
  display: grid;
  place-items: center;
  width: 25vw;
  height: 25vw;
  margin: 0 auto;
  perspective: 1200px;
  max-width: 300px;
  max-height: 300px;
}

.cubespinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: spincube 12s infinite;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--bg-secondary);
    background: rgba(var(--color-almostWhite), 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 0px var(--color-lightGray);
    font-size: 15vw;
  }
}

@for $i from 1 through 6 {
  .face#{$i} {
    transform: nth($transforms, $i);
    color: nth($colors, $i);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(0deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media (min-width: 1025px) {
  $cube-max-half-size: 150px;

  $transforms-max: translateZ($cube-max-half-size),
    rotateY(90deg) translateZ($cube-max-half-size),
    rotateY(90deg) rotateX(90deg) translateZ($cube-max-half-size),
    rotateY(180deg) rotateZ(90deg) translateZ($cube-max-half-size),
    rotateY(-90deg) rotateZ(90deg) translateZ($cube-max-half-size),
    rotateX(-90deg) translateZ($cube-max-half-size);

  .cube-wrapper {
    width: $cube-max-half-size * 2;
    height: $cube-max-half-size * 2;
  }

  .cubespinner div {
    font-size: 15vw;
  }

  @for $i from 1 through 6 {
    .face#{$i} {
      transform: nth($transforms-max, $i);
    }
  }
}

@media (min-width: 2560px) {
  $cube-large-size: 15vw;
  $transforms: translateZ($cube-large-size),
    rotateY(90deg) translateZ($cube-large-size),
    rotateY(90deg) rotateX(90deg) translateZ($cube-large-size),
    rotateY(180deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateY(-90deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateX(-90deg) translateZ($cube-large-size);
  $colors: #dd0031, #f06529, #28a4d9, #5ed4f4, #efd81d, #ec4d28;

  .cube-wrapper {
    display: grid;
    place-items: center;
    width: 30vw !important;
    height: 30vw !important;
    margin: 0 auto;
    perspective: 2400px;
    max-width: 15vw !important;
    max-height: 15vw !important;
  }

  .cubespinner {
    $percentage: 200%;
    width: $percentage;
    height: $percentage;
    position: relative;
    transform-style: preserve-3d;
    animation: spincube 12s infinite;

    div {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--bg-secondary);
      background: rgba(var(--color-almostWhite), 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 5px 0px var(--color-lightGray);
      font-size: 15vw;
    }
  }

  @for $i from 1 through 6 {
    .face#{$i} {
      transform: nth($transforms, $i);
      color: nth($colors, $i);
    }
  }
}

// Extra small devices (phones, less than 576px)
@media (max-width: 576px) {
  $cube-large-size: 22.5vw;
  $transforms: translateZ($cube-large-size),
    rotateY(90deg) translateZ($cube-large-size),
    rotateY(90deg) rotateX(90deg) translateZ($cube-large-size),
    rotateY(180deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateY(-90deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateX(-90deg) translateZ($cube-large-size);
  $colors: #dd0031, #f06529, #28a4d9, #5ed4f4, #efd81d, #ec4d28;

  .cube-wrapper {
    display: grid;
    place-items: center;
    width: 30vw !important;
    height: 30vw !important;
    margin: 0 auto;
    perspective: 2400px;
    max-width: 15vw !important;
    max-height: 15vw !important;
  }

  .cubespinner {
    $percentage: 300%;
    width: $percentage;
    height: $percentage;
    position: relative;
    transform-style: preserve-3d;
    animation: spincube 12s infinite;

    div {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--bg-secondary);
      background: rgba(var(--color-almostWhite), 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 5px 0px var(--color-lightGray);
      font-size: 30vw;
    }
  }

  @for $i from 1 through 6 {
    .face#{$i} {
      transform: nth($transforms, $i);
      color: nth($colors, $i);
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  $cube-large-size: 16vw;
  $transforms: translateZ($cube-large-size),
    rotateY(90deg) translateZ($cube-large-size),
    rotateY(90deg) rotateX(90deg) translateZ($cube-large-size),
    rotateY(180deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateY(-90deg) rotateZ(90deg) translateZ($cube-large-size),
    rotateX(-90deg) translateZ($cube-large-size);
  $colors: #dd0031, #f06529, #28a4d9, #5ed4f4, #efd81d, #ec4d28;

  .cube-wrapper {
    display: grid;
    place-items: center;
    width: 30vw !important;
    height: 30vw !important;
    margin: 0 auto;
    perspective: 2400px;
    max-width: 15vw !important;
    max-height: 15vw !important;
  }

  .cubespinner {
    $percentage: 213%;
    width: $percentage;
    height: $percentage;
    position: relative;
    transform-style: preserve-3d;
    animation: spincube 12s infinite;

    div {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--bg-secondary);
      background: rgba(var(--color-almostWhite), 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 5px 0px var(--color-lightGray);
      font-size: 24vw;
    }
  }

  @for $i from 1 through 6 {
    .face#{$i} {
      transform: nth($transforms, $i);
      color: nth($colors, $i);
    }
  }
}
