.portfolio {
  font-family: var(--font-base);

  .main-title {
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    margin: 5rem 0;
  }

  .filter-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    margin-left: 0 !important;
    margin-right: 0 !important;

    > div {
      width: auto !important;
    }

    .filter-button {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      border-radius: 25px;
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
      border: 1px solid var(--text-primary);
      background-color: var(--bg-secondary);
      color: var(--text-primary);
      &.btn-outline-primary {
        --bs-btn-color: var(--text-primary);
        --bs-btn-border-color: transparent;
        --bs-btn-hover-color: var(--bg-primary);
        --bs-btn-hover-bg: var(--text-primary);
        --bs-btn-hover-border-color: transparent;
        --bs-btn-focus-shadow-rgb: var(--accent-primary);
        --bs-btn-active-color: var(--bg-primary);
        --bs-btn-active-bg: var(--text-primary);
        --bs-btn-active-border-color: transparent;
        --bs-btn-disabled-color: var(--text-primary);
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: transparent;
        --bs-gradient: none;

        &:not(:active):not(:focus) {
          background-color: var(--text-primary);
          border-color: var(--text-primary);
          color: var(--bg-primary);
        }
      }

      &.active,
      &:hover {
        background-color: var(--text-primary);
        color: var(--bg-primary);
      }
    }

    // Dropdown styles
    .dropdown {
      position: relative !important;
      display: flex !important;
      justify-content: center !important;
      width: 100% !important;
      margin: 0 !important;

      &.show > .filter-dropdown-toggle {
        background-color: var(--bg-primary);
        color: var(--text-primary);
      }

      .filter-dropdown-toggle {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        background-color: var(--bg-primary);
        color: var(--text-primary);
        border: 1px solid var(--border-light);
        border-radius: 0.25rem;
        text-align: center;
        display: block;
        margin: 0 auto;

        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:after {
          display: none;
        }
      }

      .filter-dropdown-menu {
        position: absolute !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        top: 100% !important;
        width: 100%;
        margin-top: 0.5rem;
        border-radius: 0.25rem;
        border: 1px solid var(--border-light) !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: var(--bg-primary) !important;

        .filter-dropdown-item {
          padding: 0.75rem 1.5rem;
          color: var(--text-primary) !important;
          background-color: var(--bg-primary) !important;

          &:active,
          &.active {
            background-color: var(--border-light) !important;
            color: var(--text-primary) !important;
          }
        }
      }
    }
  }

  .work-section {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    .work-card {
      flex-basis: calc(33.33% - 1rem);
      padding: 1rem;
      border-radius: 15px;
      background-color: var(--bg-secondary);
      transition: all var(--transition-speed);
      box-shadow: var(--shadow);
      position: relative;

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 8px 24px var(--accent-primary);
      }

      .card-image {
        width: 100%;
        display: block;
        height: 200px;
        object-fit: cover;
        border-radius: var(--border-radius-small);
        transition: transform var(--transition-speed);

        &:hover {
          transform: scale(1.03);
        }
      }

      .card-body {
        .card-title {
          font-weight: 600;
          color: var(--text-primary);
          margin-top: 0.5rem;
        }

        .card-text {
          font-size: 0.9rem;
          color: var(--text-primary);
          margin-top: 0.25rem;

          .read-more {
            display: inline;
            color: var(--link);
            cursor: pointer;

            &:hover {
              color: var(--link-hover);
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-dialog {
      border-radius: 20px;
    }

    .modal-content {
      background: var(--gradient-dark);
      border-radius: 20px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
      color: var(--text-primary);
      font-family: var(--font-base);

      .modal-header {
        padding: 1.5rem;
        border-bottom: 1px solid var(--border-light);

        .modal-title {
          font-size: 2.5rem;
        }
      }

      .modal-body {
        padding: 1.5rem;

        img {
          width: 100%;
          margin-bottom: 15px;
          border-radius: 15px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        p {
          font-size: 1.1rem;
          margin-top: 1rem;
          line-height: 1.8;
        }
      }

      .modal-footer {
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.custom-button {
  font-weight: 600;
  color: var(--text-primary) !important;
  background-color: var(--bg-primary) !important;
  border: 2px solid var(--text-primary) !important;
  border-radius: 25px;
  padding: 0.5rem 1.5rem !important;
  transition: all 0.3s ease !important;
  text-decoration: none !important;

  &:hover {
    color: var(--bg-primary) !important;
    background-color: var(--text-primary) !important;
  }

  &:focus,
  &:active {
    color: var(--text-primary) !important;
    background-color: var(--link) !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

@media (max-width: 320px) {
  .portfolio .main-title {
    font-size: 1rem !important;
  }
  .portfolio .filter-section,
  .portfolio .work-section,
  .portfolio .modal-content {
    padding: 0.5rem !important;
  }
  .portfolio .work-section .work-card {
    flex-basis: 100% !important;
  }
  .modal-content {
    .modal-header,
    .modal-footer {
      flex-direction: column;
    }

    .custom-button {
      text-align: center !important;
      width: 100%;
      margin: 0.25rem 0.25rem;
    }
  }
}

@media (min-width: 321px) and (max-width: 374px) {
  .portfolio .main-title {
    font-size: 1.2rem !important;
  }
  .portfolio .work-section .work-card .card-body .card-title,
  .portfolio .work-section .work-card .card-body .card-text {
    font-size: 0.75rem !important;
  }
  .modal-content {
    .modal-header,
    .modal-footer {
      flex-direction: column;
    }

    .custom-button {
      text-align: center !important;
      width: 100%;
      margin: 0.25rem 0.25rem;
    }
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .portfolio .main-title {
    font-size: 1.4rem !important;
  }
  .portfolio .work-section .work-card {
    flex-basis: 100% !important;
  }
  .modal-content {
    .modal-header,
    .modal-footer {
      flex-direction: column;
    }

    .custom-button {
      text-align: center !important;
      width: 100%;
      margin: 0.25rem 0.25rem;
    }
  }
}

@media (min-width: 425px) and (max-width: 575.98px) {
  .portfolio .main-title {
    font-size: 1.6rem !important;
  }

  .portfolio .work-section {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .modal-content {
    .modal-header,
    .modal-footer {
      flex-direction: column;
    }

    .custom-button {
      text-align: center !important;
      width: 100%;
      margin: 0.25rem 0.25rem;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .portfolio .main-title {
    font-size: 1.6rem !important;
  }

  .portfolio .work-section {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .portfolio .main-title {
    font-size: 2rem !important;
  }

  .portfolio .work-section .work-card {
    max-width: 100% !important;
    margin-bottom: 1rem !important;
    // min-height: 24rem !important;

    .card-image {
      width: 100%;
      display: block;
      height: 200px;
      object-fit: cover;
      border-radius: var(--border-radius-small);
      transition: transform var(--transition-speed);

      &:hover {
        transform: scale(1.03);
      }
    }

    .card-body {
      .card-title {
        display: inline-flex !important;
        font-weight: 600;
        color: var(--text-primary);
        margin-top: 0.5rem;
        font-size: 1rem;
      }

      .card-text {
        display: inline-flex !important;
        font-size: 0.75rem;
        color: var(--text-primary);
        margin-top: 0.25rem;

        .read-more {
          display: inline;
          color: var(--link);
          cursor: pointer;

          &:hover {
            color: var(--link-hover);
            text-decoration: underline;
          }
        }
      }
    }
  }
  .portfolio .modal .modal-footer {
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }
}
